import translationsTabsEn from 'components/old/Menu/Tabs/translations/en.json';
import translationsTabsNl from 'components/old/Menu/Tabs/translations/nl.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

// Templates
import translationsManageEmployeesTemplateNl from 'templates/ManageEmployees/translations/nl.json';
import translationsManageEmployeesTemplateEn from 'templates/ManageEmployees/translations/en.json';
import translationsManageInvitedEmployeesTemplateNl from 'templates/ManageInvitedEmployees/translations/nl.json';
import translationsManageInvitedEmployeesTemplateEn from 'templates/ManageInvitedEmployees/translations/en.json';
import translationsManageMembersTemplateNl from 'templates/ManageMembers/translations/nl.json';
import translationsManageMembersTemplateEn from 'templates/ManageMembers/translations/en.json';
import translationsTempateInvoicesNl from 'templates/Invoices/translations/nl.json';
import translationsTempateInvoicesEn from 'templates/Invoices/translations/en.json';
import translationsmemberDetailsDetailsTemplateNl from 'templates/memberDetails/Details/translations/nl.json';
import translationsmemberDetailsDetailsTemplateEn from 'templates/memberDetails/Details/translations/en.json';
import translationsMemberDetailsEntriesTemplateNl from 'templates/memberDetails/Entries/translations/nl.json';
import translationsMemberDetailsEntriesTemplateEn from 'templates/memberDetails/Entries/translations/en.json';
import translationsMemberDetailsInvoicesTemplateNl from 'templates/memberDetails/Invoices/translations/nl.json';
import translationsMemberDetailsInvoicesTemplateEn from 'templates/memberDetails/Invoices/translations/en.json';
import translationsEmployeeDetailsDetailsTemplateNl from 'templates/employeeDetails/Details/translations/nl.json';
import translationsEmployeeDetailsDetailsTemplateEn from 'templates/employeeDetails/Details/translations/en.json';
import translationsEmployeeDetailsRightsTemplateNl from 'templates/employeeDetails/Rights/translations/nl.json';
import translationsEmployeeDetailsRightsTemplateEn from 'templates/employeeDetails/Rights/translations/en.json';
import translationsLocationSettingTemplatesNl from 'templates/locationSettings/translations/nl.json';
import translationsLocationSettingTemplatesEn from 'templates/locationSettings/translations/en.json';
import translationsGymRightGroupsTemplateNl from 'templates/gymRightGroups/translations/nl.json';
import translationsGymRightGroupsTemplateEn from 'templates/gymRightGroups/translations/en.json';
import translationReportTemplateNl from 'templates/Reports/translations/nl.json';
import translationReportTemplateEn from 'templates/Reports/translations/en.json';
import translationsEmployeeRegisterTemplateNl from 'templates/EmployeeRegister/translations/nl.json';
import translationsEmployeeRegisterTemplateEn from 'templates/EmployeeRegister/translations/en.json';
import translationsEmployerRegisterTemplateNl from 'templates/employerRegister/translations/nl.json';
import translationsEmployerRegisterTemplateEn from 'templates/employerRegister/translations/en.json';
import translationsXpTransactionsTemplateNl from 'templates/xpTransactions/translations/nl.json';
import translationsXpTransactionsTemplateEn from 'templates/xpTransactions/translations/en.json';
import translationsChallengesTemplateNl from 'templates/challenges/translations/nl.json';
import translationsChallengesTemplateEn from 'templates/challenges/translations/en.json';
import translationsStoreTemplateNl from 'templates/store/translations/nl.json';
import translationsStoreTemplateEn from 'templates/store/translations/en.json';
import translationManageRecipesNl from 'templates/ContentRecipes/translations/nl.json';
import translationManageRecipesEn from 'templates/ContentRecipes/translations/en.json';
import translationBlogTemplateNl from 'templates/ContentBlogs/translations/nl.json';
import translationBlogTemplateEn from 'templates/ContentBlogs/translations/en.json';
import translationsEmployerInvitationTemplatesNl from 'screens/GymEnvironment/ManageEmployers/translations/nl.json';
import translationsEmployerInvitationTemplatesEn from 'screens/GymEnvironment/ManageEmployers/translations/en.json';
import translationsTemplateFitnessProductsNl from 'templates/FitnessProducts/translations/nl.json';
import translationsTemplateFitnessProductsEn from 'templates/FitnessProducts/translations/en.json';
import translationsTemplateOneTimeCheckinEn from 'templates/locationSettings/OneTimeCheckin/translations/en.json';
import translationsTemplateOneTimeCheckinNl from 'templates/locationSettings/OneTimeCheckin/translations/nl.json';
import translationsTemplatePictureBankNl from 'templates/ManagePictureBank/translations/nl.json';
import translationsTemplatePictureBankEn from 'templates/ManagePictureBank/translations/en.json';
import translationsChallengeTemplateNl from 'templates/ChallengeTemplate/translations/nl.json';
import translationsChallengeTemplateEn from 'templates/ChallengeTemplate/translations/en.json';
import translationsTemplateSupplierNl from 'templates/Supplier/translations/nl.json';
import translationsTemplateSupplierEn from 'templates/Supplier/translations/en.json';
import translationsTemplateCommissionDeterminedNl from 'templates/CommissionDeterminedProducts/translations/nl.json';
import translationsTemplateCommissionDeterminedEn from 'templates/CommissionDeterminedProducts/translations/en.json';
import translationsTemplateCustomersNl from 'templates/customers/translations/nl.json';
import translationsTemplateCustomersEn from 'templates/customers/translations/en.json';
import translationsTemplateInvitationAcceptedNl from 'templates/ConfirmInvitationCompleted/translations/nl.json';
import translationsTemplateInvitationAcceptedEn from 'templates/ConfirmInvitationCompleted/translations/en.json';

// Components
import translationsLeftMenuNl from 'components/old/Menu/LeftMenu/translations/nl.json'; // TODO: remove in the future.
import translationsLeftMenuEn from 'components/old/Menu/LeftMenu/translations/en.json'; // TODO: remove in the future.
import translationsMenuNl from 'components/molecules/Menu/translations/nl.json';
import translationsMenuEn from 'components/molecules/Menu/translations/en.json';
import translationsWizardNl from 'components/organisms/RoundWizard/translations/nl.json';
import translationsWizardEn from 'components/organisms/RoundWizard/translations/en.json';

import translationsEnvironmentSelectNl from 'components/old/Menu/EnvironmentSelect/translations/nl.json';
import translationsEnvironmentSelectEn from 'components/old/Menu/EnvironmentSelect/translations/en.json';

// Hooks
import translationsChallengeModalNl from 'hooks/modals/addChallenge/translations/nl.json';
import translationsChallengeModalEn from 'hooks/modals/addChallenge/translations/en.json';
import translationsProductModalNl from 'hooks/modals/product/translations/nl.json';
import translationsProductModalEn from 'hooks/modals/product/translations/en.json';
import translationsEmailModalNl from 'hooks/modals/emailInput/translations/nl.json';
import translationsEmailModalEn from 'hooks/modals/emailInput/translations/en.json';
import translationsExcelModalNl from 'hooks/modals/excelInput/translations/nl.json';
import translationsExcelModalEn from 'hooks/modals/excelInput/translations/en.json';
import translationsNavigationMenuEn from 'hooks/menu/translations/en.json';
import translationsNavigationMenuNl from 'hooks/menu/translations/nl.json';

// Other/generic
import translationCommonNl from 'translations/common/nl.json';
import translationCommonEn from 'translations/common/en.json';
import translationsComponentNl from 'components/translations/nl.json';
import translationsComponentEn from 'components/translations/en.json';
import translationsProfileNl from 'screens/Profile/translations/nl.json';
import translationsProfileEn from 'screens/Profile/translations/en.json';
import translationsXpBalanceNl from 'components/old/Menu/XpBalance/translations/nl.json';
import translationsXpBalanceEn from 'components/old/Menu/XpBalance/translations/en.json';
import translationErrorNl from 'translations/error/nl.json';
import translationErrorEn from 'translations/error/en.json';

// Admin environment
import translationsAdminDashboardNl from 'screens/AdminEnvironment/AdminDashboard/translations/nl.json';
import translationsAdminDashboardEn from 'screens/AdminEnvironment/AdminDashboard/translations/en.json';
import translationGymsNl from 'screens/AdminEnvironment/Gyms/translations/nl.json';
import translationGymsEn from 'screens/AdminEnvironment/Gyms/translations/en.json';
import translationsManageAdminsNl from 'screens/AdminEnvironment/ManageAdmins/translations/nl.json';
import translationsManageAdminsEn from 'screens/AdminEnvironment/ManageAdmins/translations/en.json';
import translationsManageInvitedAdminsNl from 'screens/AdminEnvironment/ManageInvitedAdmins/translations/nl.json';
import translationsManageInvitedAdminsEn from 'screens/AdminEnvironment/ManageInvitedAdmins/translations/en.json';
import translationsEmployersNl from 'screens/AdminEnvironment/Employers/translations/nl.json';
import translationsEmployersEn from 'screens/AdminEnvironment/Employers/translations/en.json';
import translationsStoreNl from 'screens/AdminEnvironment/Store/translations/nl.json';
import translationsStoreEn from 'screens/AdminEnvironment/Store/translations/en.json';
import translationsAssociationsNl from 'screens/AdminEnvironment/Associations/translations/nl.json';
import translationsAssociationsEn from 'screens/AdminEnvironment/Associations/translations/en.json';
import translationsUserOverviewNl from 'screens/AdminEnvironment/UserOverview/translations/nl.json';
import translationsUserOverviewEn from 'screens/AdminEnvironment/UserOverview/translations/en.json';
import translationsAdminContentEnvironmentNl from 'screens/AdminEnvironment/ContentEnvironment/translations/nl.json';
import translationsAdminContentEnvironmentEn from 'screens/AdminEnvironment/ContentEnvironment/translations/en.json';
import translationsSuppliersOversviewNl from 'screens/AdminEnvironment/Suppliers/translations/nl.json';
import translationsSuppliersOversviewEn from 'screens/AdminEnvironment/Suppliers/translations/en.json';
import translationsCommissionDeterminedOverviewNl from 'screens/AdminEnvironment/CommissionDeterminedProductsOverview/translations/nl.json';
import translationsCommissionDeterminedOverviewEn from 'screens/AdminEnvironment/CommissionDeterminedProductsOverview/translations/en.json';
import translationsIFitCodesOverviewNl from 'screens/AdminEnvironment/IFitCodesOverview/translations/nl.json';
import translationsIFitCodesOverviewEn from 'screens/AdminEnvironment/IFitCodesOverview/translations/en.json';
import translationsTagsOverviewNl from 'screens/AdminEnvironment/Tags/translations/nl.json';
import translationsTagsOverviewEn from 'screens/AdminEnvironment/Tags/translations/en.json';
import translationsTagGroupsOverviewNl from 'screens/AdminEnvironment/TagGroups/translations/nl.json';
import translationsTagGroupsOverviewEn from 'screens/AdminEnvironment/TagGroups/translations/en.json';
import translationsCategoryOverviewNl from 'screens/AdminEnvironment/Category/translations/nl.json';
import translationsCategoryOverviewEn from 'screens/AdminEnvironment/Category/translations/en.json';
import translationsProductsOverviewNl from 'screens/AdminEnvironment/Products/translations/nl.json';
import translationsProductsOverviewEn from 'screens/AdminEnvironment/Products/translations/en.json';
import translationsProviderOverviewNl from 'screens/AdminEnvironment/Providers/translations/nl.json';
import translationsProviderOverviewEn from 'screens/AdminEnvironment/Providers/translations/en.json';
import DeclarationNl from 'screens/AdminEnvironment/Declarations/translations/nl.json';
import DeclarationEn from 'screens/AdminEnvironment/Declarations/translations/en.json';
import translationDeclarationInformationNl from 'hooks/modals/informationDeclaration/translations/nl.json';
import translationDeclarationInformationEn from 'hooks/modals/informationDeclaration/translations/en.json';
import translationsTransactionsNl from 'screens/AdminEnvironment/Transactions/translations/nl.json';
import translationsTransactionsEn from 'screens/AdminEnvironment/Transactions/translations/en.json';
import translationsTransactionsOverviewNl from 'screens/AdminEnvironment/TransactionsOverview/translations/nl.json';
import translationsTransactionsOverviewEn from 'screens/AdminEnvironment/TransactionsOverview/translations/en.json';
import transationsBookingsNl from 'screens/AdminEnvironment/Bookings/translations/nl.json';
import transationsBookingsEn from 'screens/AdminEnvironment/Bookings/translations/en.json';
import translationsSubscriptionInformationNl from 'screens/AdminEnvironment/SubscriptionInformation/translations/nl.json';
import translationsSubscriptionInformationEn from 'screens/AdminEnvironment/SubscriptionInformation/translations/en.json';

// Location environment
import translationsManageInsideMembersNl from 'screens/LocationEnvironment/ManageInsideMembers/translations/nl.json';
import translationsManageInsideMembersEn from 'screens/LocationEnvironment/ManageInsideMembers/translations/en.json';
import translationsLocationDashboardNl from 'screens/LocationEnvironment/LocationDashboard/translations/nl.json';
import translationsLocationDashboardEn from 'screens/LocationEnvironment/LocationDashboard/translations/en.json';
import translationsManageLocationRightsNl from 'screens/LocationEnvironment/ManageLocationRights/translations/nl.json';
import translationsManageLocationRightsEn from 'screens/LocationEnvironment/ManageLocationRights/translations/en.json';
import translationsLocationAreasNl from 'screens/LocationEnvironment/LocationAreas/translations/nl.json';
import translationsLocationAreasEn from 'screens/LocationEnvironment/LocationAreas/translations/en.json';
import translationsEditLocationAreaNl from 'screens/LocationEnvironment/EditLocationArea/translations/nl.json';
import translationsEditLocationAreaEn from 'screens/LocationEnvironment/EditLocationArea/translations/en.json';
import translationsProductOrderOverviewNl from 'screens/ProviderEnvironment/ManageProductOrders/translations/nl.json';
import translationsProductOrderOverviewEn from 'screens/ProviderEnvironment/ManageProductOrders/translations/en.json';
import translationLocationCustomersNl from 'screens/LocationEnvironment/LocationCustomers/translations/nl.json';
import translationLocationCustomersEn from 'screens/LocationEnvironment/LocationCustomers/translations/en.json';
import translationLocationCustomerDetailsNl from 'screens/LocationEnvironment/LocationCustomerDetails/translations/nl.json';
import translationLocationCustomerDetailsEn from 'screens/LocationEnvironment/LocationCustomerDetails/translations/en.json';
import translationLocationCustomerResultsNl from 'screens/LocationEnvironment/LocationCustomerResults/translations/nl.json';
import translationLocationCustomerResultsEn from 'screens/LocationEnvironment/LocationCustomerResults/translations/en.json';
import translationLocationCustomerProgressionNl from 'screens/LocationEnvironment/LocationCustomerProgression/translations/nl.json';
import translationLocationCustomerProgressionEn from 'screens/LocationEnvironment/LocationCustomerProgression/translations/en.json';
import translationsManageLocationEmployeesNl from 'screens/LocationEnvironment/ManageLocationEmployees/translations/nl.json';
import translationsManageLocationEmployeesEn from 'screens/LocationEnvironment/ManageLocationEmployees/translations/en.json';
import translationsEditEmployeeNl from 'hooks/modals/locationEmployeeDetails/translations/nl.json';
import translationsEditEmployeeEn from 'hooks/modals/locationEmployeeDetails/translations/en.json';

// Gym environment
import translationsHomeNl from 'screens/GymEnvironment/Home/translations/nl.json';
import translationsHomeEn from 'screens/GymEnvironment/Home/translations/en.json';
import translationManageLocationsNl from 'screens/GymEnvironment/ManageLocations/translations/nl.json';
import translationManageLocationsEn from 'screens/GymEnvironment/ManageLocations/translations/en.json';
import translationsManageSubscriptionsNl from 'screens/GymEnvironment/ManageSubscriptions/translations/nl.json';
import translationsManageSubscriptionsEn from 'screens/GymEnvironment/ManageSubscriptions/translations/en.json';
import translationsManageInactiveSubscriptionsNl from 'screens/GymEnvironment/ManageInactiveSubscriptions/translations/nl.json';
import translationsManageInactiveSubscriptionsEn from 'screens/GymEnvironment/ManageInactiveSubscriptions/translations/en.json';
import translationsEditSubscriptionNl from 'screens/GymEnvironment/EditSubscription/translations/nl.json';
import translationsEditSubscriptionEn from 'screens/GymEnvironment/EditSubscription/translations/en.json';
import translationsManageSubscriptionVariantLocationsNl from 'screens/GymEnvironment/ManageSubscriptionVariantLocations/translations/nl.json';
import translationsManageSubscriptionVariantLocationsEn from 'screens/GymEnvironment/ManageSubscriptionVariantLocations/translations/en.json';
import translationsManageSubscriptionMembersNl from 'screens/GymEnvironment/ManageSubscriptionMembers/translations/nl.json';
import translationsManageSubscriptionMembersEn from 'screens/GymEnvironment/ManageSubscriptionMembers/translations/en.json';
import translationsSettingsNl from 'screens/GymEnvironment/GymSettings/translations/nl.json';
import translationsSettingsEn from 'screens/GymEnvironment/GymSettings/translations/en.json';
import translationEditGymRegistrationSettingsNl from 'screens/GymEnvironment/GymRegistrationSettings/translations/nl.json';
import translationEditGymRegistrationSettingsEn from 'screens/GymEnvironment/GymRegistrationSettings/translations/en.json';
import translationsGymGroupRightSettingsNl from 'screens/GymEnvironment/GymRightGroupSettings/translations/nl.json';
import translationsGymGroupRightSettingsEn from 'screens/GymEnvironment/GymRightGroupSettings/translations/en.json';
// Authentication environment
import translationsAdminRegisterNl from 'screens/AuthenticationEnvironment/AdminRegister/translations/nl.json';
import translationsAdminRegisterEn from 'screens/AuthenticationEnvironment/AdminRegister/translations/en.json';
import translationsTfaNl from 'screens/AuthenticationEnvironment/Tfa/translations/nl.json';
import translationsTfaEn from 'screens/AuthenticationEnvironment/Tfa/translations/en.json';
import translationsLoginNl from 'screens/AuthenticationEnvironment/Login/translations/nl.json';
import translationsLoginEn from 'screens/AuthenticationEnvironment/Login/translations/en.json';
import translationsPasswordForgottenNl from 'screens/AuthenticationEnvironment/PasswordForgotten/translations/nl.json';
import translationsPasswordForgottenEn from 'screens/AuthenticationEnvironment/PasswordForgotten/translations/en.json';
import translationResetPasswordNl from 'screens/AuthenticationEnvironment/ResetPassword/translations/nl.json';
import translationResetPasswordEn from 'screens/AuthenticationEnvironment/ResetPassword/translations/en.json';
import translationsDeleteAccountNl from 'screens/AuthenticationEnvironment/DeleteAccount/translations/nl.json';
import translationsDeleteAccountEn from 'screens/AuthenticationEnvironment/DeleteAccount/translations/en.json';
import translationsUnsubscribeNl from 'screens/AuthenticationEnvironment/UnsubscribePage/translations/nl.json';
import translationsUnsubscribeEn from 'screens/AuthenticationEnvironment/UnsubscribePage/translations/en.json';
// Checkout environment
import translationsCheckoutNl from 'screens/CheckoutEnvironment/Checkout/translations/nl.json';
import translationsCheckoutEn from 'screens/CheckoutEnvironment/Checkout/translations/en.json';
import translationsCheckoutCompleteNl from 'screens/CheckoutEnvironment/CheckoutComplete/translations/nl.json';
import translationsCheckoutCompleteEn from 'screens/CheckoutEnvironment/CheckoutComplete/translations/en.json';
//Association environment
import translationsAssociationLocationsNl from 'screens/AssociationEnvironment/Locations/translations/nl.json';
import translationsAssociationLocationsEn from 'screens/AssociationEnvironment/Locations/translations/en.json';
//Employer environment
import translationsEmployerDashboardNl from 'screens/EmployerEnvironment/EmployerDashboard/translations/nl.json';
import translationsEmployerDashboardEn from 'screens/EmployerEnvironment/EmployerDashboard/translations/en.json';
import translationsEmployerKpisNl from 'screens/EmployerEnvironment/EmployerKpis/translations/nl.json';
import translationsEmployerKpisEn from 'screens/EmployerEnvironment/EmployerKpis/translations/en.json';
import translationsManageEmployeesNl from 'screens/EmployerEnvironment/ManageEmployees/translations/nl.json';
import translationsManageEmployeesEn from 'screens/EmployerEnvironment/ManageEmployees/translations/en.json';
import translationsEmployerSettingsNl from 'screens/EmployerEnvironment/Settings/translations/nl.json';
import translationsEmployerSettingsEn from 'screens/EmployerEnvironment/Settings/translations/en.json';
import translationsManageEmployersChallengesNl from 'screens/EmployerEnvironment/challenge/Overview/translations/nl.json';
import translationsManageEmployersChallengesEn from 'screens/EmployerEnvironment/challenge/Overview/translations/en.json';
//Content environment
import translationsManageContentCreatorsEn from 'screens/ContentEnvironment/ManageContentCreators/translations/en.json';
import translationsManageContentCreatorsNl from 'screens/ContentEnvironment/ManageContentCreators/translations/nl.json';
import translationsManageContentRightsEn from 'screens/ContentEnvironment/ManageContentRight/translations/en.json';
import translationsManageContentRightsNl from 'screens/ContentEnvironment/ManageContentRight/translations/nl.json';
//Supplier environment
import translationsManageProductOrdersNl from 'screens/SupplierEnvironment/ProductOrders/translations/nl.json';
import translationsManageProductOrdersEn from 'screens/SupplierEnvironment/ProductOrders/translations/en.json';
//Provider environment
import translationsSubscriptionOverviewNl from 'screens/Pages/ProvidersSubscriptions/translations/nl.json';
import translationsSubscriptionOverviewEn from 'screens/Pages/ProvidersSubscriptions/translations/en.json';
//ManageProviders
import translationsManageProvidersPageNl from 'screens/Pages/ManageProviders/translations/nl.json';
import translationsManageProvidersPageEn from 'screens/Pages/ManageProviders/translations/en.json';
//AccessDeniedPage
import translationsAccessDeniedPageNl from 'screens/Pages/AccessDeniedPage/translations/nl.json';
import translationsAccessDeniedPageEn from 'screens/Pages/AccessDeniedPage/translations/en.json';
//UsersPage
import translationsUsersPageNl from 'screens/Pages/Users/translations/nl.json';
import translationsUsersPageEn from 'screens/Pages/Users/translations/en.json';
//PageNotFound
import translationsPageNotFoundNl from 'screens/Pages/PageNotFound/translations/nl.json';
import translationsPageNotFoundEn from 'screens/Pages/PageNotFound/translations/en.json';
//PermissionsOnRoles
import translationsPermissionsOnRolesNl from 'screens/Pages/PermissionsOnRoles/translations/nl.json';
import translationsPermissionsOnRolesEn from 'screens/Pages/PermissionsOnRoles/translations/en.json';
//ProvidersEmployersChallenges
import translationsProviderEmployerChallengesNl from 'screens/Pages/ProvidersEmployersChallenges/translations/nl.json';
import translationsProviderEmployerChallengesEn from 'screens/Pages/ProvidersEmployersChallenges/translations/en.json';
//EmployersChallenges
import translationsEmployersChallengesNl from 'screens/Pages/EmployersChallenges/translations/nl.json';
import translationsEmployersChallengesEn from 'screens/Pages/EmployersChallenges/translations/en.json';
//Missing Page
import translationsMissingPageNl from 'screens/Pages/MissingPage/translations/nl.json';
import translationsMissingPageEn from 'screens/Pages/MissingPage/translations/en.json';
//IFitDashboard
import translationsIFitDashboardNl from 'screens/Pages/IFitDashboard/translations/nl.json';
import translationsIFitDashboardEn from 'screens/Pages/IFitDashboard/translations/en.json';

const resources = {
    en: {
        common: translationCommonEn,
        gyms: translationGymsEn,
        locations: translationManageLocationsEn,
        tabs: translationsTabsEn,
        wizard: translationsWizardEn,
        manageAdmins: translationsManageAdminsEn,
        manageInvitedAdmins: translationsManageInvitedAdminsEn,
        adminRegister: translationsAdminRegisterEn,
        store: translationsStoreEn,
        components: translationsComponentEn,
        tfa: translationsTfaEn,
        login: translationsLoginEn,
        employeeRegisterTemplate: translationsEmployeeRegisterTemplateEn,
        employerRegisterTemplate: translationsEmployerRegisterTemplateEn,
        profile: translationsProfileEn,
        leftMenu: translationsLeftMenuEn,
        menu: translationsMenuEn,
        manageSubscriptions: translationsManageSubscriptionsEn,
        manageInactiveSubscriptions: translationsManageInactiveSubscriptionsEn,
        editSubscription: translationsEditSubscriptionEn,
        manageSubscriptionVariantLocations: translationsManageSubscriptionVariantLocationsEn,
        manageSubscriptionMembers: translationsManageSubscriptionMembersEn,
        checkout: translationsCheckoutEn,
        checkoutComplete: translationsCheckoutCompleteEn,
        passwordForgotten: translationsPasswordForgottenEn,
        resetPassword: translationResetPasswordEn,
        settings: translationsSettingsEn,
        manageInsideMembers: translationsManageInsideMembersEn,
        locationDashboard: translationsLocationDashboardEn,
        manageLocationRights: translationsManageLocationRightsEn,
        home: translationsHomeEn,
        manageEmployeesTemplate: translationsManageEmployeesTemplateEn,
        manageInvitedEmployeesTemplate: translationsManageInvitedEmployeesTemplateEn,
        manageMembersTemplate: translationsManageMembersTemplateEn,
        memberDetailsDetailsTemplate: translationsmemberDetailsDetailsTemplateEn,
        memberDetailsEntriesTemplate: translationsMemberDetailsEntriesTemplateEn,
        memberDetailsInvoicesTemplate: translationsMemberDetailsInvoicesTemplateEn,
        invoicesTemplate: translationsTempateInvoicesEn,
        employeeDetailsDetailsTemplate: translationsEmployeeDetailsDetailsTemplateEn,
        employeeDetailsRightsTemplate: translationsEmployeeDetailsRightsTemplateEn,
        gymRegistrationSettings: translationEditGymRegistrationSettingsEn,
        gymRightGroupSettings: translationsGymGroupRightSettingsEn,
        environmentSelect: translationsEnvironmentSelectEn,
        locationSettingsTemplate: translationsLocationSettingTemplatesEn,
        gymRightsGroupTemplate: translationsGymRightGroupsTemplateEn,
        reportsTemplate: translationReportTemplateEn,
        locationAreas: translationsLocationAreasEn,
        editLocationArea: translationsEditLocationAreaEn,
        employers: translationsEmployersEn,
        xpBalance: translationsXpBalanceEn,
        xpTransactionsTemplate: translationsXpTransactionsTemplateEn,
        challengesTemplate: translationsChallengesTemplateEn,
        storeTemplate: translationsStoreTemplateEn,
        recipeTemplate: translationManageRecipesEn,
        blogTemplate: translationBlogTemplateEn,
        associations: translationsAssociationsEn,
        userOverview: translationsUserOverviewEn,
        associationLocations: translationsAssociationLocationsEn,
        adminDashboard: translationsAdminDashboardEn,
        deleteAccount: translationsDeleteAccountEn,
        challengeTemplate: translationsChallengeTemplateEn,
        challengeModal: translationsChallengeModalEn,
        productModal: translationsProductModalEn,
        employerDashboard: translationsEmployerDashboardEn,
        employerKpis: translationsEmployerKpisEn,
        emailModal: translationsEmailModalEn,
        excelModal: translationsExcelModalEn,
        manageEmployees: translationsManageEmployeesEn,
        employerSettings: translationsEmployerSettingsEn,
        contentEnvironmentOverview: translationsAdminContentEnvironmentEn,
        manageContentCreators: translationsManageContentCreatorsEn,
        manageContentRights: translationsManageContentRightsEn,
        employerInvitations: translationsEmployerInvitationTemplatesEn,
        templateFitnessProducts: translationsTemplateFitnessProductsEn,
        oneTimeCheckin: translationsTemplateOneTimeCheckinEn,
        pictureBank: translationsTemplatePictureBankEn,
        suppliersOverview: translationsSuppliersOversviewEn,
        suppliersTemplate: translationsTemplateSupplierEn,
        commissionDetermined: translationsCommissionDeterminedOverviewEn,
        commissionDeterminedTemplate: translationsTemplateCommissionDeterminedEn,
        productOrdersOverview: translationsProductOrderOverviewEn,
        supplierProductOrders: translationsManageProductOrdersEn,
        locationCustomers: translationLocationCustomersEn,
        locationCustomerDetails: translationLocationCustomerDetailsEn,
        templateCustomers: translationsTemplateCustomersEn,
        locationCustomerResults: translationLocationCustomerResultsEn,
        locationCustomerProgression: translationLocationCustomerProgressionEn,
        iFitCodesOverview: translationsIFitCodesOverviewEn,
        locationEmployees: translationsManageLocationEmployeesEn,
        editLocationEmployee: translationsEditEmployeeEn,
        confirmInvitationCompleted: translationsTemplateInvitationAcceptedEn,
        entreoProductTag: translationsTagsOverviewEn,
        entreoProductTagGroup: translationsTagGroupsOverviewEn,
        entreoProductCategory: translationsCategoryOverviewEn,
        entreoProduct: translationsProductsOverviewEn,
        entreoSubscription: translationsSubscriptionOverviewEn,
        providerOverview: translationsProviderOverviewEn,
        unsubscribe: translationsUnsubscribeEn,
        declaration: DeclarationEn,
        accessDenied: translationsAccessDeniedPageEn,
        navigationMenu: translationsNavigationMenuEn,
        usersPage: translationsUsersPageEn,
        pageNotFound: translationsPageNotFoundEn,
        providers: translationsManageProvidersPageEn,
        permissionsOnRoles: translationsPermissionsOnRolesEn,
        manageEmployersChallenges: translationsManageEmployersChallengesEn,
        providerEmployersChallenges: translationsProviderEmployerChallengesEn,
        employersChallenges: translationsEmployersChallengesEn,
        missingPage: translationsMissingPageEn,
        iFitDashboard: translationsIFitDashboardEn,
        useInformationDeclaration: translationDeclarationInformationEn,
        error: translationErrorEn,
        transactions: translationsTransactionsEn,
        transactionsOverview: translationsTransactionsOverviewEn,
        translationsBookings: transationsBookingsEn,
        subscriptionInformation: translationsSubscriptionInformationEn
    },
    nl: {
        common: translationCommonNl,
        gyms: translationGymsNl,
        locations: translationManageLocationsNl,
        tabs: translationsTabsNl,
        wizard: translationsWizardNl,
        manageAdmins: translationsManageAdminsNl,
        manageInvitedAdmins: translationsManageInvitedAdminsNl,
        adminRegister: translationsAdminRegisterNl,
        store: translationsStoreNl,
        components: translationsComponentNl,
        tfa: translationsTfaNl,
        login: translationsLoginNl,
        employeeRegisterTemplate: translationsEmployeeRegisterTemplateNl,
        employerRegisterTemplate: translationsEmployerRegisterTemplateNl,
        profile: translationsProfileNl,
        leftMenu: translationsLeftMenuNl,
        menu: translationsMenuNl,
        manageSubscriptions: translationsManageSubscriptionsNl,
        manageInactiveSubscriptions: translationsManageInactiveSubscriptionsNl,
        editSubscription: translationsEditSubscriptionNl,
        manageSubscriptionVariantLocations: translationsManageSubscriptionVariantLocationsNl,
        manageSubscriptionMembers: translationsManageSubscriptionMembersNl,
        checkout: translationsCheckoutNl,
        checkoutComplete: translationsCheckoutCompleteNl,
        passwordForgotten: translationsPasswordForgottenNl,
        resetPassword: translationResetPasswordNl,
        settings: translationsSettingsNl,
        manageInsideMembers: translationsManageInsideMembersNl,
        locationDashboard: translationsLocationDashboardNl,
        manageLocationRights: translationsManageLocationRightsNl,
        home: translationsHomeNl,
        manageEmployeesTemplate: translationsManageEmployeesTemplateNl,
        manageInvitedEmployeesTemplate: translationsManageInvitedEmployeesTemplateNl,
        manageMembersTemplate: translationsManageMembersTemplateNl,
        memberDetailsDetailsTemplate: translationsmemberDetailsDetailsTemplateNl,
        memberDetailsEntriesTemplate: translationsMemberDetailsEntriesTemplateNl,
        memberDetailsInvoicesTemplate: translationsMemberDetailsInvoicesTemplateNl,
        invoicesTemplate: translationsTempateInvoicesNl,
        employeeDetailsDetailsTemplate: translationsEmployeeDetailsDetailsTemplateNl,
        employeeDetailsRightsTemplate: translationsEmployeeDetailsRightsTemplateNl,
        gymRegistrationSettings: translationEditGymRegistrationSettingsNl,
        gymRightGroupSettings: translationsGymGroupRightSettingsNl,
        environmentSelect: translationsEnvironmentSelectNl,
        locationSettingsTemplate: translationsLocationSettingTemplatesNl,
        gymRightsGroupTemplate: translationsGymRightGroupsTemplateNl,
        reportsTemplate: translationReportTemplateNl,
        locationAreas: translationsLocationAreasNl,
        editLocationArea: translationsEditLocationAreaNl,
        employers: translationsEmployersNl,
        xpBalance: translationsXpBalanceNl,
        xpTransactionsTemplate: translationsXpTransactionsTemplateNl,
        challengesTemplate: translationsChallengesTemplateNl,
        storeTemplate: translationsStoreTemplateNl,
        recipeTemplate: translationManageRecipesNl,
        blogTemplate: translationBlogTemplateNl,
        associations: translationsAssociationsNl,
        userOverview: translationsUserOverviewNl,
        associationLocations: translationsAssociationLocationsNl,
        adminDashboard: translationsAdminDashboardNl,
        deleteAccount: translationsDeleteAccountNl,
        challengeTemplate: translationsChallengeTemplateNl,
        challengeModal: translationsChallengeModalNl,
        productModal: translationsProductModalNl,
        employerDashboard: translationsEmployerDashboardNl,
        employerKpis: translationsEmployerKpisNl,
        emailModal: translationsEmailModalNl,
        excelModal: translationsExcelModalNl,
        manageEmployees: translationsManageEmployeesNl,
        employerSettings: translationsEmployerSettingsNl,
        contentEnvironmentOverview: translationsAdminContentEnvironmentNl,
        manageContentCreators: translationsManageContentCreatorsNl,
        manageContentRights: translationsManageContentRightsNl,
        templateFitnessProducts: translationsTemplateFitnessProductsNl,
        oneTimeCheckin: translationsTemplateOneTimeCheckinNl,
        employerInvitations: translationsEmployerInvitationTemplatesNl,
        pictureBank: translationsTemplatePictureBankNl,
        suppliersOverview: translationsSuppliersOversviewNl,
        suppliersTemplate: translationsTemplateSupplierNl,
        commissionDetermined: translationsCommissionDeterminedOverviewNl,
        commissionDeterminedTemplate: translationsTemplateCommissionDeterminedNl,
        productOrdersOverview: translationsProductOrderOverviewNl,
        supplierProductOrders: translationsManageProductOrdersNl,
        locationCustomers: translationLocationCustomersNl,
        locationCustomerDetails: translationLocationCustomerDetailsNl,
        templateCustomers: translationsTemplateCustomersNl,
        locationCustomerResults: translationLocationCustomerResultsNl,
        locationCustomerProgression: translationLocationCustomerProgressionNl,
        iFitCodesOverview: translationsIFitCodesOverviewNl,
        locationEmployees: translationsManageLocationEmployeesNl,
        editLocationEmployee: translationsEditEmployeeNl,
        confirmInvitationCompleted: translationsTemplateInvitationAcceptedNl,
        entreoProductTag: translationsTagsOverviewNl,
        entreoProductTagGroup: translationsTagGroupsOverviewNl,
        entreoProductCategory: translationsCategoryOverviewNl,
        entreoProduct: translationsProductsOverviewNl,
        entreoSubscription: translationsSubscriptionOverviewNl,
        providerOverview: translationsProviderOverviewNl,
        unsubscribe: translationsUnsubscribeNl,
        declaration: DeclarationNl,
        accessDenied: translationsAccessDeniedPageNl,
        navigationMenu: translationsNavigationMenuNl,
        usersPage: translationsUsersPageNl,
        pageNotFound: translationsPageNotFoundNl,
        providers: translationsManageProvidersPageNl,
        permissionsOnRoles: translationsPermissionsOnRolesNl,
        manageEmployersChallenges: translationsManageEmployersChallengesNl,
        providerEmployersChallenges: translationsProviderEmployerChallengesNl,
        employersChallenges: translationsEmployersChallengesNl,
        missingPage: translationsMissingPageNl,
        iFitDashboard: translationsIFitDashboardNl,
        useInformationDeclaration: translationDeclarationInformationNl,
        error: translationErrorNl,
        transactions: translationsTransactionsNl,
        transactionsOverview: translationsTransactionsOverviewNl,
        translationsBookings: transationsBookingsNl,
        subscriptionInformation: translationsSubscriptionInformationNl
    }
};

const ns = Object.keys(resources.nl);

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources,
        lng: 'nl',
        fallbackLng: 'nl',
        supportedLngs: ['en', 'nl'],
        debug: true,

        // have a common namespace used around the full app
        ns,
        defaultNS: 'common',

        interpolation: {
            escapeValue: false,
            format: (value, rawFormat, lng) => {
                const [format, ...additionalValues] = (rawFormat?.split(',') ?? []).map((v) => v.trim());

                switch (format) {
                    case 'price':
                        // Currency can be used like t('common:currency', {value: 62.44})
                        // Different currency can be used like t('common:currency', {value: 62.44, context:dollar})
                        return Intl.NumberFormat(lng, {
                            style: 'currency',
                            currency: additionalValues[0]
                        }).format(value);
                }

                const momentValue = moment(value);
                if (momentValue.isValid()) {
                    return momentValue.format(format);
                }

                return value;
            }
        }
    });

i18n.on('languageChanged', function (lng) {
    moment.locale(lng);
});

export const getLanguage = () => i18n.language || 'nl';

export default i18n;
