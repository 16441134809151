import { useSession } from 'contexts/sessionContext';
import { CustomError, useApi } from 'hooks/api';
import { AllLocationEmployerInvitationQueryOptions } from 'hooks/locationEmployer';
import { UseQueryOptions, useQuery } from 'react-query';
import { appendObject, appendObjectArray } from 'utils/objectAppender';
import { buildUriWithParams } from 'utils/uriHelper';

export type EmployerQueryOptions = {
    locationId?: string;
}

export interface AddEmployerModel {
    name: string;
    email: string;
}

export interface EmployerPlainModel {
    id: string;
    name: string;
    hasAccepted: boolean;
}

export interface EmployerWithLocationModel {
    id: string;
    name: string;
    locationName?: string;
    locationId?: string;
    hasAccepted: boolean;
}

export interface GymEmployerPlainInvitationModel {
    id: string;
    gymName: string;
    inviteeEmail: string;
    invitedByEmail: string;
    created: Date;
}

export interface EmployerEmployeeInvolvementsDataModel {
    totalActive: number;
    amountOfEmployees: number;
    percentageJoinedChallenge: number;
    percentageCompletedChallenge: number;
    mostPopularChallengeName?: string;
}

export interface EmployerInvoiceModel {
    id: string;
    invoiceDate: Date;
    pdfUrl: string;
    orderTotal: number;
}

export interface EmployerSettingsModel {
    name: string;
    description?: string;
    logoHash?: string;
    personnelNumber: boolean;
    fitness: boolean;
    homeTrainEquipment: boolean;
    onlineSports: boolean;
    associations: boolean;
    contractType: ContractType;
    contractExtension: ContractExtensionType;
    employerContribution: number;
    maxMonthlyEntreoPointForEmployee: number;
    contacts?: EmployerContact[];
    addresses?: EmployerAddress[];
    licenseFees: number;
    isPaymentBruto: boolean;
    maxEntreosInEuros: number;
    amountOfEmployees: number;
    maxGrossAmount?: number;
    declarationAllowed: boolean;
    declarationInformationText?: string;
    maxDeclarableBruto?: number;
}

export interface EmployerSettingsInputModel {
    employerId: string;
    name: string;
    description?: string;
    logo?: File;
    personnelNumber: boolean;
    fitness: boolean;
    homeTrainEquipment: boolean;
    onlineSports: boolean;
    associations: boolean;
    contacts: EmployerContact[];
    addresses: EmployerAddress[];
    maxGrossAmount?: number;
    declarationAllowed: boolean;
    declarationInformationText?: string;
}

export interface EmployerFinancialSettingsInputModel {
    employerId: string;
    contractType: ContractType;
    employerContribution: number;
    licenseFees: number;
    isPaymentBruto: boolean;
    maxEntreosInEuros: number;
    maxDeclarableBruto: number;
}

export interface EmployerContact {
    id?: string;
    firstName: string;
    insertion?: string;
    lastName: string;
    description?: string;
    phoneNumber: string;
    email: string;
    registrations: boolean;
    newsletters: boolean;
    invoices: boolean;
}

export interface EmployerAddress {
    id?: string;
    addressType: AddressType;
    street: string;
    houseNumber: string;
    postalCode: string;
    city: string;
    country: CountryType;
}

export enum AddressType {
    billing = 1,
    visiting = 2
}

export enum ContractType {
    Bruto = 1,
    Netto = 2,
    AutomaticCollection = 3
}

export enum ContractExtensionType {
    OneYear = 1,
    TwoYears = 2,
    ThreeYears = 3
}

export enum CountryType {
    NL = 0
}

export const useEmployers = () => {
    const { callApi } = useApi();
    const { session } = useSession();

    const addEmployer = async (values: AddEmployerModel) => {
        const response = await callApi.current('/employerInvitation/v1', {
            method: 'POST',
            body: JSON.stringify(values)
        });

        return response;
    };

    const deleteEmployerInvitation = async (id: string) => {
        const response = await callApi.current(`/employerInvitation/v1/${id}`, {
            method: 'DELETE'
        });

        return response;
    };

    const allEmployers = async () => {
        const response = await callApi.current<EmployerPlainModel[]>('/employer/v1');

        return response;
    };

    const allEmployersWithLocations = async (queryParams?: AllLocationEmployerInvitationQueryOptions) => {
        const uri = buildUriWithParams('/employer/v1/locations', queryParams);
        const response = await callApi.current<EmployerWithLocationModel[]>(uri);
        return response;
    };

    const addBalance = async (amountOfXp: number) => {
        const response = await callApi.current(`/employer/v1/${session?.activeEmployerId}/AddBalance`, {
            method: 'POST',
            body: JSON.stringify({
                amountOfXp
            })
        });

        return response;
    };

    const getEmployeeInvolvements = async (employerId: string) => {
        const response = await callApi.current<EmployerEmployeeInvolvementsDataModel>(`/employer/v1/involvements/${employerId}`);
        return response;
    };

    const getEmployerInvoices = async (employerId: string) => {
        const response = await callApi.current<EmployerInvoiceModel[]>(`/employer/v1/invoices/${employerId}`);
        return response;
    };

    const getEmployerSettings = async (employerId: string) => {
        const response = await callApi.current<EmployerSettingsModel>(`/employer/v1/settings/${employerId}`);
        return response;
    };

    const getEmployersByLocationOrGym = async (queryParams?: AllLocationEmployerInvitationQueryOptions) => {
        const uri = buildUriWithParams('/employer/v1/sort', queryParams);
        const response = await callApi.current<EmployerPlainModel[]>(uri);
        return response;
    };

    const updateEmployerSettings = async (model: EmployerSettingsInputModel) => {
        let formData = new FormData();
        formData = appendObject(model, formData);
        if (model.logo !== undefined) {
            formData.append('logo', model.logo);
        }
        if (model.addresses !== undefined) {
            appendObjectArray(model.addresses, formData, 'addresses');
        }
        if (model.contacts !== undefined) {
            appendObjectArray(model.contacts, formData, 'contacts');
        }

        const response = await callApi.current('/employer/v1/settings', { method: 'PUT', body: formData });
        return response;
    };

    const updateEmployerFinancialSettings = async (model: EmployerFinancialSettingsInputModel) => {
        const response = await callApi.current('/employer/v1/financialSettings', { method: 'PUT', body: JSON.stringify(model) });
        return response;
    };

    const searchEmployers = async (partialName: string) => {
        const response = await callApi.current<EmployerPlainModel[]>(`/employer/v1/search/${partialName}`);
        return response;
    };

    const useSearchEmployers = (partialName: string, options?: UseQueryOptions<EmployerPlainModel[], CustomError[]>) => {
        const queryKey = ['employers.search', partialName];

        return useQuery<EmployerPlainModel[], CustomError[]>(queryKey, async () => {
            const response = await searchEmployers(partialName);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        }, options);
    };

    return {
        addEmployer,
        allEmployers,
        allEmployersWithLocations,
        addBalance,
        getEmployeeInvolvements,
        deleteEmployerInvitation,
        getEmployerInvoices,
        getEmployerSettings,
        getEmployersByLocationOrGym,
        updateEmployerSettings,
        updateEmployerFinancialSettings,
        useSearchEmployers
    };
};
