export const icons: { [key: string]: string } = {
    active: 'active',
    'add-file': 'add-file',
    add: 'add',
    admin: 'admin',
    'area-chart': 'area-chart',
    area: 'area',
    'arrow-right': 'arrow-right',
    bandage: 'bandage',
    bell: 'bell',
    'box-important': 'box-important',
    calendar: 'calendar',
    calories: 'calories',
    cancel: 'cancel',
    cart: 'cart',
    challenge: 'challenge',
    check_bold: 'check-bold',
    'check-circle': 'check-circle',
    check: 'check',
    'chevron-down-small': 'chevron-down-small',
    'chevron-down': 'chevron-down',
    'chevron-left': 'chevron-left',
    'chevron-right': 'chevron-right',
    'chevron-up': 'chevron-up',
    company: 'company',
    cross: 'cross',
    customer: 'customer',
    cycling: 'cycling',
    dashboard: 'dashboard',
    'date-add': 'date-add',
    decrease: 'decrease',
    delete: 'delete',
    difficulty: 'difficulty',
    edit: 'edit',
    employer: 'employer',
    equipment: 'equipment',
    euro: 'euro',
    eye: 'eye',
    'file-upload': 'file-upload',
    Filter: 'Filter',
    finane: 'finane',
    gym: 'gym',
    help: 'help',
    home: 'home',
    increase: 'increase',
    info: 'info',
    'insert-row': 'insert-row',
    intake: 'intake',
    invoice: 'invoice',
    kpi: 'kpi',
    lesson: 'lesson',
    lock: 'lock',
    manage: 'manage',
    marker: 'marker',
    members: 'members',
    memberships: 'memberships',
    move: 'move',
    new: 'new',
    ok: 'ok',
    online: 'online',
    orders: 'orders',
    pdf: 'pdf',
    person: 'person',
    picture: 'picture',
    plus: 'plus',
    points: 'points',
    prize: 'prize',
    qr: 'qr',
    question: 'question',
    remove: 'remove',
    report: 'report',
    rest: 'rest',
    rights: 'rights',
    'rounded-active': 'rounded-active',
    'rounded-challenge': 'rounded-challenge',
    'rounded-gym': 'rounded-gym',
    'rounded-steps': 'rounded-steps',
    routing: 'routing',
    running: 'running',
    search: 'search',
    settings: 'settings',
    shop: 'shop',
    suppliers: 'suppliers',
    swimming: 'swimming',
    synchronize: 'synchronize',
    teams: 'teams',
    theme: 'theme',
    timer: 'timer',
    walking: 'walking',
    wallet: 'wallet',
    invite: 'invite',
    bold: 'bold',
    italic: 'italic',
    'bulleted-list': 'bulleted-list',
    'numbered-list': 'numbered-list',
    dots: 'dots',
    underline: 'underline',
    ellipsis: 'ellipsis',

    // Dashboard
    entreo_dashboard: 'entreo_dashboard',
    view_for_entreo: 'view_for_entreo',
    view_for_employers: 'view_for_employers',
    view_for_providers: 'view_for_providers',

    // Authorization
    authorization: 'authorization',
    users: 'users',
    roles_permissions: 'roles_permissions',
    send_invitations: 'send_invitations',

    // Finance
    finance: 'finance',
    manage_payments: 'manage_payments',
    declarations: 'declarations',
    employer_license_fee: 'employer_license_fee',
    license_fee: 'license_fee',
    bfa: 'bfa',

    // Employers
    employers: 'employers',
    manage_users: 'manage_users',
    employers_challengess: 'employers_challengess',

    // Providers
    providers: 'providers',
    manage_providers: 'manage_providers',
    manage_providers_employees: 'manage_providers_employees',
    manage_providers_challenges: 'manage_providers_challenges',
    manage_subscriptions: 'manage_subscriptions',
    manage_employers: 'manage_employers',
    manage_employers_challenges: 'manage_employers_challenges',

    // Challenges
    challenges: 'challenges',
    manage_challenges_template: 'manage_challenges_template',
    approve_challenges: 'approve_challenges',

    // Products
    products: 'products',
    product_services: 'product_services',
    manage_orders: 'manage_orders',
    manage_suppliers: 'manage_suppliers',
    supplier_products: 'supplier_products',

    // Content
    content: 'content',
    blog: 'blog',
    recipes: 'recipes',
    images: 'images',

    // Store
    products_services: 'products_services',

    // Configuration
    configuration: 'configuration',
    tags: 'tags',
    categories_products: 'categories_products',
    subscription_task: 'subscription_task'
};

export type Icons = typeof icons;

