import React, { useCallback, useEffect, useState } from 'react';
import {
    UseTableOptions,
    useTable,
    useGlobalFilter,
    TableState,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useSortBy,
    SortingRule,
    ActionType
} from 'react-table';
import { Wrapper, Table as StyledTable, Th, THead, Tr, TableWrapper, EmptyList } from './styles';
import Search from './components/Search';
import Footer from './components/Footer';
import { FloatingAction } from './components/Action/index';
import Margin from 'components/atoms/Margin';
import TableLoading from '../TableLoading';
import useActions from './hooks/useActions';
import useRowSelection from './hooks/useRowSelection';
import Icon from 'components/atoms/Icon';
import { H1, H4, Paragraph } from 'components/atoms/text';
import { Button } from 'components/atoms/button';
import { useTranslation } from 'react-i18next';
import { Group } from 'components/molecules/Group/styles';
import Tabs, { Tab } from 'components/molecules/Tabs';
import { Action } from './components/Action/types';
import TableRow, { RowExtendableProps } from './components/TableRow';

export interface RowProps<T extends object> {
    onClick?: (item: T) => void;
}

export interface TableTab<T extends object> extends Omit<Tab, 'value' | 'amount'> {
    filter: (item: T) => boolean;
    onClick?: (item: T) => void;
}

interface TableProps<T extends object> extends UseTableOptions<T> {
    search?: boolean;
    defaultFilterValue?: string | number;
    onAddClick?: () => void;
    onAddClickLabel?: string;
    tabs?: TableTab<T>[];
    actions?: Action<T>[];
    selectActions?: {
        label: string;
        onClick: () => void;
    }[];
    rowActions?: Action<T>[];
    isRowSelect?: boolean;
    selectedRows?: number;
    isLoading?: boolean;
    onStateChange?: (state: TableState<T>, action?: ActionType) => void;
    row?: RowProps<T>;
    defaultSort?: SortingRule<T>;
    showFooterWithSingularPage?: boolean;
    placeholder?: string;
    rowExtendableProps?: RowExtendableProps<T>;
}

const Table = <T extends object>({
    columns,
    data,
    search,
    onAddClick,
    onAddClickLabel,
    tabs = [],
    actions,
    defaultSort,
    rowActions,
    selectActions = [],
    selectedRows = 0,
    isLoading,
    initialState,
    row: rowProps,
    isRowSelect,
    onStateChange,
    getRowId,
    showFooterWithSingularPage = true,
    placeholder,
    rowExtendableProps
}: TableProps<T>) => {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const [calculatedTabs, setCalculatedTabs] = useState<Tab[]>([]);
    const [filteredData, setFilteredData] = useState<readonly T[]>([]);
    const { t } = useTranslation('common');

    // Methods.
    const stateReducer = useCallback(
        (newState: TableState<T>, action: ActionType) => {
            if (onStateChange) {
                onStateChange(newState, action);
            }
            return newState;
        },
        [onStateChange]
    );

    useEffect(() => {
        setFilteredData(tabs != null && tabs.length > 0 ? data.filter(tabs[tabIndex].filter) : data);
        setCalculatedTabs(tabs ? tabs.map((tab, index) => ({ ...tab, value: index, amount: data.filter(tab.filter).length })) : []);
    }, [tabIndex, data]);

    // Hooks.
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        setGlobalFilter,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        visibleColumns,
        state: { pageIndex, globalFilter, pageSize },
        pageCount,
        gotoPage
    } = useTable<T>(
        {
            columns,
            data: filteredData,
            initialState: {
                sortBy: defaultSort ? [defaultSort] : []
            },
            getRowId,
            stateReducer
        },
        useGlobalFilter,
        useFlexLayout,
        useSortBy,
        usePagination,
        useRowSelect,
        // Custom hooks.
        useActions<T>([...(actions ?? rowActions ?? []).filter(({ hide }) => !hide)]),
        useRowSelection<T>(isRowSelect)
    );

    const resetFilter = () => {
        setGlobalFilter('');
    };

    return (
        <>
            {(calculatedTabs.length || search || onAddClick) && (
                <Margin bottom={1}>
                    {(calculatedTabs.length > 0 || onAddClick) && (
                        <Group spaceBetween={!!calculatedTabs} right={!calculatedTabs}>
                            {calculatedTabs.length > 0 && (
                                <Tabs tabs={calculatedTabs} current={tabIndex} setCurrent={(val) => setTabIndex(val as number)} />
                            )}
                            {onAddClick && !search && (
                                <Button alignSelf="center" noMargin rounded brand="pink" onClick={onAddClick}>
                                    {onAddClickLabel ? onAddClickLabel : 'Toevoegen'}
                                </Button>
                            )}
                        </Group>
                    )}
                    {search && (
                        <Search
                            filter={globalFilter}
                            setFilter={setGlobalFilter}
                            onAddClick={onAddClick}
                            onAddClickLabel={onAddClickLabel}
                        />
                    )}
                </Margin>
            )}
            <Wrapper>
                <TableWrapper>
                    <StyledTable {...getTableProps()}>
                        <THead>
                            {headerGroups.map((headerGroup) => (
                                <Tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <Icon name="chevron-down" />
                                                    ) : (
                                                        <Icon name="chevron-up" />
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </THead>
                        {isLoading ? (
                            <TableLoading<T> columns={visibleColumns} />
                        ) : (
                            <tbody {...getTableBodyProps()}>
                                {page.length > 0 ? (
                                    page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <TableRow row={row} rowProps={rowProps} showFooterWithSingularPage={showFooterWithSingularPage} rowExtendableProps={rowExtendableProps} />
                                        );
                                    })
                                ) : placeholder == null ? (
                                    <EmptyList>
                                        <td colSpan={headerGroups[0].headers.length}>
                                            <Margin bottom={1}>
                                                <Icon name="dashboard" size={3} />
                                            </Margin>
                                            <Margin bottom={1}>
                                                <H1 noMargin>{t('listview.notification')}</H1>
                                            </Margin>
                                            <Margin bottom={2}>
                                                <H4 grey={300}>{t('listview.no-results')}</H4>
                                            </Margin>
                                            {globalFilter && (
                                                <Button alignSelf="center" rounded brand="pink" onClick={resetFilter}>
                                                    {t('listview.reset-filter')}
                                                </Button>
                                            )}
                                        </td>
                                    </EmptyList>
                                ) : (
                                    <td>
                                        <Margin top={1}>
                                            <Paragraph center grey={300}>
                                                {placeholder}
                                            </Paragraph>
                                        </Margin>
                                    </td>
                                )}
                            </tbody>
                        )}
                    </StyledTable>
                </TableWrapper>
                {(showFooterWithSingularPage || pageCount > 1) && (
                    <Footer
                        totalRows={rows.length}
                        previousPage={previousPage}
                        nextPage={nextPage}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                    />
                )}
            </Wrapper>
            {selectActions.length > 0 && selectedRows > 0 && <FloatingAction actions={selectActions} selected={selectedRows} />}
        </>
    );
};

export default Table;
