import React, { FC, useState } from 'react';
import InvalidFeedback from '../InvalidFeedback';
import { Wrapper, DateIcon } from './styles';
import { useTheme } from '@emotion/react';
import Icon from 'components/atoms/Icon';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { nl, enGB } from 'date-fns/locale';

export interface FormDateProps extends ReactDatePickerProps {
    error?: string;
}

const FormDate: FC<FormDateProps> = ({ error, ...props }) => {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<Date | null>(props.selected ?? null);
    const locale = i18n.language === 'nl' ? nl : enGB;

    const theme = useTheme();

    return (
        <>
            <>
                <Wrapper focus={isOpen}>
                    <DateIcon>
                        <Icon name="calendar" size={.85} color={theme.colors.dark} />
                    </DateIcon>
                    <DatePicker
                        {...props}
                        portalId="layers"
                        onCalendarOpen={() => setIsOpen(true)}
                        onCalendarClose={() => setIsOpen(false)}
                        open={isOpen}
                        locale={locale}
                        selected={selected}
                        onSelect={(date) => setSelected(date)}
                        dateFormat="dd-MM-y"
                        showYearDropdown
                        showMonthDropdown
                        popperPlacement="bottom" 
                    />
                </Wrapper>
            </>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormDate;
